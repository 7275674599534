let timelineElements = [
    {
      id: 1,
      title: "Opening Ceremony",
      location: "SLC 2.303 (7PM - 9PM)",
      description:
       "Meet the brothers/Info Session for Theta Tau Alpha",
     
      date: "September 3rd, 2024",
      icon: "work",
    },
    {
      id: 2,
      title: "Innovation Night",
      location: "ECSW 1.355 (7PM - 9PM)",
      description:
      "Join us for a fun and interactive innovation night to meet the brothers of Theta Tau Alpha, an engineering fraternity committed to academic excellence and professional growth. Discover our values, activities, and how Theta Tau Alpha can enhance your engineering experience.",
      buttonText: "View Backend Projects",
      date: "September 5th, 2024",
      icon: "work",
    },
    {
      id: 3,
      title: "Torch Relay",
      location: "UV Phase 8 Pool (7PM - 9PM)",
      description:
        "Come join us for a delightful barbecue and get to know the brothers of Theta Tau Alpha, all while savoring delicious food.",
      buttonText: "Company Website",
      date: "September 9th, 2024",
      icon: "work",
    },
    {
      id: 4,
      title: "Olympic Games",
      location: "TBA (7PM - 9PM)",
      description:
        "Enjoy a mix of team and individual games in a fun-filled atmosphere, with plenty of opportunities to showcase your skills and build bonds with the brothers!",
      buttonText: "Course Certificate",
      date: "September 10th, 2024",
      icon: "work",
    },
    {
      id: 5,
      title: "Professional Night *INVITE ONLY*",
      location: "TBA",
      description:
        "If granted an invitation, you'll have the chance to attend a distinguished professional event featuring one of our professionals representing esteemed organizations such as Lockheed Martin, JPMorgan Chase & Co., State Farm, and more!",
      buttonText: "College Projects",
      date: "September 11th/12th, 2024",
      icon: "work",
    },
    {
      id: 6,
      title: "Interviews",
      location: "TBA",
      description:
        "If a rushee is chosen, we will conduct interviews to gain deeper insights and foster a genuine connection with them.",
      date: "TBA",
      icon: "work",
    },
  ];
  
  export default timelineElements;