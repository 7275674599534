import React from 'react'
import LandingPage from '../LandingPage'
import Footer from './Footer'

const Home = () => {
  return (
    <div>

        <LandingPage/>
        <Footer/>
    </div>
  )
}

export default Home